import React from "react";
import "./Register.css";
import { connect } from "react-redux";
import { HashRouter as Router, Link } from "react-router-dom";
import MaleDoctor from "../../assets/male_doctor.png";
import FemaleDoctor from "../../assets/female_doctor.png";
import JoinImage from "../../assets/join_us.png";
import swal from "sweetalert";
import { registerUser } from "../../actions/Home";
import $ from "jquery";
import { getStreamCourseLevels } from "../../actions/Listings";
import logo from  '../../assets/predictor_logo.png';
import doctor_group from  '../../assets/college_predictor_doctor_group.png';
import { NavLink } from "react-router-dom";
import check1 from '../../assets/check1.png';
class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            phase: 1,
            checked: false,
            gender: "Male",
            fname: "",
            lname: "",
            email: "",
            mobile: "",
            state: "",
            city: "",
            pwd: "",
            conf_pwd: "",
            stream: "",
            course_level: "",
            int_states: [],
            showModal:false,
        };
        // this.state = { error : '', phase : 1, checked : false, gender : 'Male', fname : 'Satish', lname : 'Paramagond', mobile : '9743881008', email : 'satish@goocampus.in', password : 'satishmp', password_confirmation : 'satishmp', player_id : '', state : '', city : '', int_states : [], stream : '', course_level : '', pwd : 'satishmp008', conf_pwd : 'satishmp008' };
        this.handlePhaseChange = this.handlePhaseChange.bind(this);
        this.handleLoginClick = this.handleLoginClick.bind(this);
    }

    componentDidMount() {
        if (this.props.is_auth) {
            window.location.hash = "#/";
        }
        this.props.onGetStreamCourseLevels(1)
    }

    // handleChangeStream = stream => {
    //     this.setState({ stream: stream });
    //     this.props.onGetStreamCourseLevels(stream);
    // };
    handleShowModal = () =>{this.setState({showModal:true})}
    handleCloseModal = () => { this.setState({showModal:false})}
    handleAddState = state => {
        let { int_states } = this.state;

        if (int_states.includes(state.toString())) {
            const state_index = int_states.indexOf(state.toString());
            int_states.splice(state_index, 1);
        } else int_states.push(state.toString());

        this.setState({ int_states: int_states });
    };

    handlePhaseChange = () => {
        var email_reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        var mobile_reg = /^[0-9]{10}$/;

        const { fname, lname, email, mobile, state, pwd, conf_pwd,checked } =
            this.state;

        if (
            fname === "" ||
            lname === "" ||
            email === "" ||
            mobile === "" ||
            state === "" ||
            pwd === ""||
            checked===false
        ) {
            this.setState({ error: "Fill all required fields." });
        } else if (!email_reg.test(email)) {
            this.setState({ error: "Enter valid email id." });
        } else if (!mobile_reg.test(mobile)) {
            this.setState({ error: "Enter valid mobile numebr." });
        } else if (pwd.length < 8 || pwd !== conf_pwd) {
            this.setState({
                error: "Both passwords should be same and have atleast 8 characters."
            });
        }else if (!checked){
          this.setState({ error: "Please accept terms and conditions." });
        } else {
            this.setState({ error: ""});
        }
    };

    handleRegisterSubmit = e => {
        e.preventDefault();

        const {
            gender,
            fname,
            lname,
            email,
            mobile,
            state,
            city,
            int_states,
            stream,
            course_level,
            pwd,
            conf_pwd,
            checked,
        } = this.state;

        swal({
            title: "Are you sure?",
            text: "The information you have provided will be saved in your user profile, You can change these information later.",
            buttons: ["Cancel", "Register"]
        }).then(willRegister => {
            if (willRegister) {
                var formData = new FormData(
                    document.getElementById("register_form")
                );
                formData.append("fname", fname);
                formData.append("lname", lname);
                formData.append("gender", gender);
                formData.append("email", email);
                formData.append("mobile", mobile);
                formData.append("state", state);
                formData.append("city", city);
                formData.append("password", pwd);
                formData.append("confirm_password", conf_pwd);
                formData.append("int_stream", stream);
                formData.append("int_course_level", course_level);
                formData.append("int_states", int_states.toString());
                this.props.onRegisterUser(formData);
            }
        });
    };

    handleLoginClick = e => {
        // Show login page
        $(".login_container")
            .removeClass("d-none")
            .removeClass("slideOutRight")
            .addClass("slideInRight");
    };

    render() {
        const { states, streams, levels } = this.props;
        const {
            error,
            phase,
            checked,
            gender,
            fname,
            lname,
            email,
            mobile,
            state,
            city,
            pwd,
            conf_pwd,
            stream,
            course_level,
            int_states
        } = this.state;

        return (
            <div className="row register_container mt-4 pt-5" >
              <p>{this.state.showModal}</p>
              {this.state.showModal && (
        <div className="modal fade show terms_conditions_modal" style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Terms and Conditions</h5>
                <button
                  type="button"
                  className="close"
                  onClick={this.handleCloseModal}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {/* Load the terms page dynamically using iframe */}
                <iframe
  src="/#/terms_conditions"
  style={{ width: "100%", height: "500px", border: "none" ,overflow:"scrollY" }}
  title="Terms & Conditions"
  scrolling="yes"
/>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn text-white"
                  style={{background:"#f47523"}}
                  onClick={this.handleCloseModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show" onClick={this.handleCloseModal}></div>
        </div>
      )}
                <div className="col">
                   
                    <div className="row">
                    <div className='col-md-6 background_image_predictor_side'>
                    <p className="blue-color mt-5 text-left px-5  login-header">NEET College Predictor</p>          
  <div className="predictor_cards mx-5 mt-5 bg-white">
    <div className="d-flex">
      <div className="login-content-img">
        <img src={check1} width={20} alt="predictor" />
      </div>
      <div className="login-content-desc">
        <p className="blue-color">
          Updates on 2023-2024 closing for MCC-AIQ/ Deemed and State Authorities
        </p>
      </div>
    </div>
  </div>
  <div className="predictor_cards mx-5 mt-5 bg-white">
    <div className="d-flex">
      <div className="login-content-img">
        <img  src={check1} width={20} alt="predictor" />
      </div>
      <div className="login-content-desc">
        <p className="blue-color">
        Tuition Fee provided for all specialities & all categories of Government, Management, NRI etc quota
        </p>
      </div>
    </div>
  </div>
  <div className="predictor_cards mx-5 mt-5 bg-white">
    <div className="d-flex">
      <div className="login-content-img">
        <img src={check1} width={20} alt="predictor" />
      </div>
      <div className="login-content-desc">
        <p className="blue-color">
        Need personalised counselling guidance or admission support for NEET- PG 2025
        &nbsp;&nbsp;&nbsp;<a href="">Click Here</a>
        </p>
      </div>
    </div>
  </div>
        </div>
        <div className="col-md-5 predictor_register_form_div">
            <div className='my-5 bg-white rounded-border pt-5 px-4' >
            <img src={logo} alt="Logo" width={"100"} height="100" className="logo_predictor_mobile m-auto" />
            <div>
            <h1 className='text-center font-weight-bold blue-color register_heading_text'>Hi Doctor</h1>
            <p className='text-center'>Welcome to <span style={{color:"#F47523"}}>Goo</span>Campus</p>
            
          </div>
            <form  id="register_form" onSubmit={this.handleRegisterSubmit}>
  <div className="mt-3">
    {/* <div className="form-group text-center register_gender">
      <label>
        <input type="radio" name="" className="form-control" value="Male" checked={gender === "Male"} onChange={() => this.setState({ gender: "Male" })} required
        />{" "}
        <img src={MaleDoctor} alt="Doctor" height="100" />
      </label>
      <span className="mx-3">or</span>
      <label>
        <input type="radio" name="" className="form-control" value="Female" checked={gender === "Female"} onChange={() => this.setState({ gender: "Female" })} required
        />{" "}
        <img src={FemaleDoctor} alt="Doctor" height="100" />
      </label>
    </div> */}

       <div className="row ">  
        <div className="col-md-6">  
          <label className="blue-color">First Name</label> 
            <input type="text" name="" className="form-control text-capitalize" placeholder="First name *" value={fname} onChange={e => this.setState({ fname: e.target.value })} required/>
        </div>
        <div className="col-md-6 second_input">   
        <label className="blue-color">Last Name </label> 
            <input type="text" name="" className="form-control text-capitalize" placeholder="Last name *" value={lname} onChange={e => this.setState({ lname: e.target.value })} required />
         </div>   
      </div>
  
    <label className="mt-2">Email ID</label>
    <input type="email" name="" className="form-control mt-1" placeholder="Email ID *" value={email} onChange={e => this.setState({ email: e.target.value })} required />
     
    <div className="row mt-3">
        <div className="col-md-6">
        <label className="blue-color">Mobile </label> 
        <input type="number" name="" className="form-control" placeholder="Mobile Number *" value={mobile} onChange={e => this.setState({ mobile: e.target.value })} required />
        </div>
        <div className="col-md-6 second_input">
        <label>Course Level</label>
        <div className="form-group input-group">
         
      <select name="" className="form-control" value={course_level} onChange={e => this.setState({ course_level: e.target.value })} required
      >
        <option value="">Interested course level *</option>
        {levels &&
          levels.map(level => (
            <option key={level.id} value={level.id}>
              {level.level_name}
            </option>
          ))}
      </select>
    </div>
        </div>
    </div>
  
      
    

  <div className="row">
    <div className="col-md-6">
      <label className="blue-color">Native State</label>
    <select name="" className="form-control" value={state} onChange={e => this.setState({ state: e.target.value })} required >
        <option value="">Native state *</option>
        {states &&
          states.map(state => (
            <option key={state.id} value={state.id}>
              {state.state_name}
            </option>
          ))}
      </select>
    </div>
    <div className="col-md-6 second_input">
    <label className="blue-color">City</label>
    <input type="text" name="" className="form-control" value={city} onChange={e => this.setState({ city: e.target.value })} placeholder="Enter your city" />
    </div>
  </div>
     

      <div className="row mt-3">
        <div className="col-md-6">
          <label>Password</label>
        <input type="password" name="" id="reg_pwd" className="form-control" placeholder="Password *" value={pwd} onChange={e => this.setState({ pwd: e.target.value })} required  />
        </div>
        <div className="col-md-6 second_input">
        <label>Re-Type Password</label>
        <input type="password" name="" id="reg_cfrm_pwd" className="form-control" placeholder="Re-type Password *" value={conf_pwd} onChange={e => this.setState({ conf_pwd: e.target.value })} required />
        </div>
      </div>
    

      
      
    
          <input type="hidden" name="int_stream" value={1}/>
    
    
    
    

    
<div className="text-center">
    <input className="mt-5" type="checkbox" checked={checked}  onChange={() => this.setState({ checked: !checked })}
   required />
    <span className="small_text ml-2 terms_check">
      I agree to all the{" "}
      <Router>
        <Link to="/terms_conditions" className="blue-color" data-toggle="modal" onClick={this.handleShowModal} data-target="#exampleModal">Terms & conditions</Link>
      </Router>{" "}
      of GooCampus.
    </span>
    </div>
    <div className="form-group text-center mt-4">
      <button type="submit" style={{background:"#F47523",borderRadius:"10px"}} className="btn register_button mb-4 text-white py-2" disabled={ !course_level }
      >
        <strong>Create Account</strong> 
        {/* <i className="fa fa-user-plus ml-1"></i> */}
      </button>
      <br/>
      <div className="pb-4">
      <span className="d-inline">Already have an account? <Link to={'/login'} className="d-inline" style={{color:"#233974"}}>Login</Link> </span>
     
      {/* <button className='btn bg-orange text-center w-50 text-white borderradius my-3'> <b>Login</b> </button> */}
      </div>
    </div>
 
   
  </div>
  

</form>
            </div>
        
        </div>
        <div className="col-md-1"></div>
                    </div>
                    
                </div>
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        main_loader: state.home.main_loader,
        is_auth: state.home.is_auth,
        states: state.state.college_states,
        streams: state.list.streams,
        levels: state.list.course_levels
    };
};

const mapActionsToProps = {
    onRegisterUser: registerUser,
    onGetStreamCourseLevels: getStreamCourseLevels
};

export default connect(mapStatesToProps, mapActionsToProps)(Register);
